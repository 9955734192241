.stateContainer,
.stateHeadContainer,
.categoryColumnContainer,
.stateTitleContainer,
.summaryContainer {
	display: flex;
}

.stateContainer,
.stateSubContainer {
	position: absolute;
	top: 0;
	flex-direction: column;
	align-items: center;
	min-height: 95%;
	width: 100vw;
	z-index: 5;
	color: white;
}

.stateContainer {
	/* height: 100%; */
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	overflow: scroll;
	overflow-x: hidden;
	min-height: 100vh;
}

.stateContainer::-webkit-scrollbar {
	display: none;
}

.stateSubContainer {
	margin-top: 64px;
	background-color: rgba(0, 0, 0, 0.8);
	min-height: 100vh;
	display: flex;
}

.stateHeadContainer {
	padding-top: 20px;
	width: 100%;
	/* height: 30vh; */
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.stateTitleContainer {
	width: 100vw;
	/* height: 100%; */
	/* background-color: teal; */
	flex-direction: column;
}
.summaryContainer {
	width: 100%;
	height: 100%;
	justify-content: center;
}
.flagImageContainer {
	width: 30%;
	height: 100%;
}

.flagSvg {
	width: 100%;
	height: 100%;
}

.stateTitle {
	width: 100vw;
	height: 100%;
	/* margin-left: 10%; */
}

.closeStateBtnContainer {
	position: fixed;
	right: 0;
	top: 0;
	z-index: 10;
}

.overall {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	/* border: solid black 1px; */
	/* background-color: teal; */
	/* margin-right: 5px; */
}

.summary {
	max-width: 50vw;
	height: 100%;
	padding: 20px;
	font-size: 24px;
	/* background-color: teal; */
	/* border: solid black 1px; */
}

.stateRaceLegend {
	width: 350px;
	height: auto;
	background-color: #80808090;
	border-radius: 5px;
	color: #fff;
	padding: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
}
@keyframes clickme {
	0%{
		box-shadow: 0px 0px 0px 0px grey;
	}
	50% {
		box-shadow: 0px 0px 2px 2px grey;
	}
	100% {
		box-shadow: 0px 0px 0px 0px grey;
	}
}

.stateRaceLegend > h4 {
	font-size: 20px;
}

.legendRaceContainer {
	width: 100%;
}

.legendRace {
	width: auto;
	display: flex;
	align-items: center;
	margin: 5px;
}

.legendRace:hover {
	cursor: pointer;
}

.raceColor {
	width: 100%;
	border-radius: 3px;
	padding: 8px;
	transition: all linear 100ms;
}

.categoryColumnContainer {
	width: 100vw;
	justify-content: center;
	flex-wrap: wrap;
}

@media only screen and (max-width: 600px) {
	/* .stateTitleContainer {
		width: 30%;
	} */
	.summaryContainer {
		width: 90%;
	}
	.summary {
		max-width: 100%;
		font-size: 18px;
	}
}
/* .involvedVisfalse {
	position: absolute;
	top: 0px;
	height: 0px;
	width: 100%;
	overflow: hidden;
	transition: height 1s;
	z-index: 5;
	background-color: rgb(0, 0, 0, 0.9);
} */

.involvedVis {
	/* background-color: rgb(0, 0, 0, 0.9); */
	overflow: hidden;
	position: absolute;
	top: 0px;
	height: 100%;
	width: 100%;
	/* z-index: 5; */
	transition: height 1s;
}

.summaryLink {
	color: #FCE21B;
	text-decoration: none;
	transition: all linear 100ms;
}

.summaryLink:visited {
	color: #FCE21B;
}

.summaryLink:hover {
	color: #11ABAB;
}

@media screen and (max-width: 325px) {
	.stateRaceLegend {
		width: calc(100vw - 30px);
		height: auto;
	}

	.legendRace {
		font-size: 13px;
	}
}

@media screen and (max-width: 600px) {
	.stateSubContainer {
		margin-top: 56px;
	}
}
