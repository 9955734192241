.landingPage {
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
}
.bg-image {
	filter: blur(5px);
	-webkit-filter: blur(5px);
	background-image: url('https://cdn.vox-cdn.com/thumbor/QuTsf9KyR4gCaUviJoZy_98U6DY=/0x0:5568x3712/1200x675/filters:focal(2339x1411:3229x2301)/cdn.vox-cdn.com/uploads/chorus_image/image/66908888/1218460006.jpg.0.jpg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: absolute;
	width: 100vw;
	height: 100vh;
}
#transition-template {
	width: 20%;
	position: fixed;
	top: 105px;
	left: 2px;
	height: 67vh;
}
#BLMimage {
	position: absolute;
	width: auto;
	height: 90%;
	z-index: 6;
}

.fullPageLanding {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #00000050;
}
/* #BLMimage:nth-child(1){
  position: absolute;
  top: 20px;
  left:20px;
}
#BLMimage:nth-child(3){
  position: absolute;
  top: 350px;
  left:50px;
}
#BLMimage:nth-child(2){
  position: absolute;
  top: 120px;
  right:10px;
}
#BLMimage:nth-child(4){
  position: absolute;
  top: 520px;
  right:15px;
}
#BLMimage:nth-child(5){
  position: absolute;
  bottom: 10px;
  left:10px;
}
#BLMimage:nth-child(6){
  position: absolute;
  top: 220px;
  right:10px;
}
#BLMimage:nth-child(7){
  position: absolute;
  top: 20px;
  left:20px;
}
#BLMimage:nth-child(8){
  position: absolute;
  top: 20px;
  right:20px;
}
#BLMimage:nth-child(9){
  position: absolute;
  top: 20px;
  left:20px;
}
#BLMimage:nth-child(10){
  position: absolute;
  top: 20px;
  left:320px;
} */

@keyframes fadeA {
	0% {
		opacity: 1;
	}
	20% {
		opacity: 0;
	}
	40% {
		opacity: 0;
	}
	60% {
		opacity: 0;
	}
	80% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
.landingPage p:nth-child(1) {
	animation-delay: 100ms;
}
.landingPage p:nth-child(2) {
	animation-delay: 100ms;
}
.landingPage p:nth-child(3) {
	animation-delay: 100ms;
}
.landingPage p:nth-child(4) {
	animation-delay: 100ms;
}
.landingPage p:nth-child(5) {
	animation-delay: 100ms;
}

.span-title {
	opacity: 0;
	animation: spanTitle 500ms;
}
.span-title2 {
	opacity: 0;
	animation: spanTitle2 500ms;
}
@keyframes spanTitle {
	from {
		opacity: 1;
	}
	to {
		display: none;
	}
}
@keyframes spanTitle2 {
	0% {
		opacity: 1;
		text-decoration: line-through;
	}
	5% {
		opacity: 0.3;
		text-decoration: line-through;
	}
	100% {
		opacity: 0.3;
		text-decoration: line-through;
	}
}
.span-title:nth-child(1) {
	animation-delay: 500ms;
}
.span-title:nth-child(2) {
	animation-delay: 500ms;
}
.span-title2 {
	animation-delay: 500ms;
}

.header {
	width: 90vw;
	background-color: rgba(0, 0, 0, 0.8);
	color: yellow;
	margin-top: 20px;
	display: flex;
	justify-content: center;
	z-index: 1;
	font-size: 45px;
}
.header > h3 {
	font-size: 40px;
	margin: 20px;
	white-space: nowrap;
}

.missionStatement {
	margin-top: 50px;
	height: 400px;
	width: 700px;
	border: solid black 1px;
	margin-bottom: 50px;
	text-align: left;
	display: flex;
	color: white;
	overflow: auto;
	text-align: center;
	color: #ffff00c9;

	flex-direction: column;
	justify-content: space-evenly;
	z-index: 1;
	background-color: rgba(0, 0, 0, 0.8);
}
.quote-div {
	width: 95%;
	/* border:2px solid red; */
	padding: 10px;
	height: 30%;
	z-index: 1;
	position: relative;
}
.quote-div > cite {
	position: absolute;
	right: 0;
	margin-top: 15px;
	bottom: 0;
}
.bg-text {
	background-color: rgb(0, 0, 0); /* Fallback color */
	background-color: rgba(0, 0, 0, 0.4); /* Black w/opacity/see-through */
	color: white;
	font-weight: bold;
	border: 3px solid #f1f1f1;

	transform: translate(-50%, -50%);
	z-index: 2;
	width: 80%;
	padding: 20px;
	text-align: center;
}

@media only screen and (max-width: 600px) {
	.missionStatement {
		width: 80%;
		height: 500px;
	}
	.quote-div {
		text-align: left;
	}
	.quote-div > cite {
		right: 10px;
	}
}
.zIndex {
	z-index: 1;
}

#downArrowBtn {
	position: absolute;
	bottom: 0;
	left: calc(50vw - 32px);
}

.clearBg {
	height: 100%;
	width: 100%;
	background-color: #ffffff30;
}

.section {
	position: relative;
}

.landingH1 {
	color: white;
}

.sourceSeals {
	height: calc(45vw / 3);
	width: calc(45vw / 3);
	padding: 20px;
}

.textLeft {
	text-align: left;
}

.textCenter {
	text-align: center;
}

.textRight {
	text-align: right;
}

.flexCol {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100vw;
}

.landingBtn {
	height: 100px;
	width: 300px;
	border-radius: 5px;
	background-color: #000;
	color: #fce21b;
	font-size: 36px;
	border: none;
	transition: all linear 100ms;
}

.landingBtn:visited {
	text-decoration: none;
}

.landingBtn:hover {
	/* box-shadow: 0 0 5px 0 black; */
	color: white;
	cursor: pointer;
	background-color: #171515;
}

.repeatLandingBtn {
	margin-top: 100px;
	height: 100px;
	width: 300px;
	border-radius: 5px;
	background-color: #fce21b;
	color: none;
	font-size: 36px;
	border: none;
	transition: all linear 100ms;
}

.repeatLandingBtn:visited {
	text-decoration: none;
}

.repeatLandingBtn:hover {
	/* box-shadow: 0 0 5px 0 black; */
	color: white;
	cursor: pointer;
	background-color: #11abab;
}

.crestContainer {
	border: 2px solid white;
	border-radius: 5px;
	width: 50vw;
	padding: 20px;
	margin: 10px;
}

.landingPlus {
	font-size: 120px;
}

.lastText {
	padding: 100px 20px;
}

@media screen and (min-width: 1050px) {
	.sourceSeals {
		height: calc(35vw / 3);
		width: calc(35vw / 3);
	}
	.crestContainer {
		margin: 0;
	}
	.landingPlus {
		font-size: 60px;
		margin: 0;
		padding: 0;
	}
}

@media screen and (max-width: 1000px) {
	.landingH1 {
		font-size: 50px !important;
	}
}

@media screen and (max-width: 920px) {
	.crestContainer {
		width: 80vw;
	}
}

@media screen and (max-width: 860px) {
	.sourceSeals {
		height: calc(70vw / 3);
		width: calc(70vw / 3);
		padding: 10px;
	}
}

@media screen and (max-width: 560px) {
	.landingH1 {
		font-size: 48px !important;
	}
}

@media screen and (max-width: 510px) {
	.landingH1 {
		font-size: 32px !important;
	}
}
