* {
	box-sizing: border-box;
	scroll-behavior: smooth;
}

body::-webkit-scrollbar {
	display: none;
}

body {
	box-sizing: border-box;
	font-family: Helvetica, sans-serif;
	background-color: #303030;
}

h1 {
	font-size: 3rem;
	text-align: center;
}

.App {
	text-align: center;
	position: relative;
	height: 100vh;
	width: 100vw;
}

.button {
	border: none;
	border-radius: 5px;
	background-color: black;
	color: white;
	/* margin-left: 25px;
  margin-right: 25px;
  margin-top: 15px;
  margin-bottom: 15px; */
  margin: 5px;
  padding: 10px;
	/* width: 100px;
  height: 30px; */
  font-size: 20px;
	transition: all linear 100ms;
	z-index: 10;
}

.button:hover {
	color: black;
	background-color: #fce21b;
	cursor: pointer;
}

.button:focus {
	outline: none;
}

@media (max-width: 600px) {

}
