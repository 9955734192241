.get-involved-container {
  /* min-width: 400px; */
  width: 90vw;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* font-size: 30px; */
  /* position: relative; */
  word-wrap: normal;
  justify-content: flex-start;
  padding: 10px;
}

.get-involved-container > div {
  margin-top: 30px;
}



.senators {
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.senator-container {
  background-color: #fce21b95;
  width: 350px;
  height: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
  margin: 10px;
}

.senator-name {
  margin: 10px;
}

.media-links {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.contact-form {
  margin: 10px;
  width: auto;
  height: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
}

.linkButton:hover {
  color: black;
}

/* @media(max-width: 700px){
  .senators{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .senator-container {
    padding: 20px;
    height: 400px;
    width: 300px;
    margin: 10px;
    justify-content: flex-start;
  }

  .senator-name {
    margin: 5px;
  }
} */
/* .MuiPaper-root {
  position: relative;
  top:50px;
} */
/* @media (max-width: 500px){
  .get-involved-container{
    font-size: 20px;
  }
} */