#loading-cont {
    width:100%;
    height:100vh;
    z-index: 5;
    position:fixed;
    animation: fadeOut 120s infinite 20s;
    background-position: center;
    background-size: cover;
    background-image: url('https://cdn.dribbble.com/users/132438/screenshots/12227088/amplify_black_voices_-_1.png');
    display: flex;
    justify-content: center;
    align-items: center;
}
@keyframes fadeOut {
    0% {opacity: 1.0;}
    1% {opacity: 0.0; display:none; z-index:-1}
    100% {opacity: 0.0; display:none; z-index:-1}
  }