.categoryContainer {
  min-width: 250px;
  max-width: 350px;
	background-color: #80808090;
	border-radius: 5px;
	margin: 5px;
	padding: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.categoryTitle {
	width: 100%;
	height: 10%;
  font-size: 28px;
  padding: 5px;
	/* background-color: white; */
	/* margin-top: 10px; */
	/* margin-bottom: 10px; */
	align-items: center;
	justify-content: center;
}

.rankContainer {
	width: 100%;
	height: 15%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
}

.chartContainer {
	width: 100%;
	margin-top: 10px;
	margin-bottom: 10px;
}

.categoryDescription {
	font-size: 14px;
}