.odometer {
	font-size: 45px;
	margin-left: 25px;
}

.odometerContainer {
	display: flex;
	justify-content: center;
	align-items: baseline;
}

.rankOrdinal {
    font-size: 24px;
    width: 1em;
}
